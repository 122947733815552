import axios from 'axios';
// import Vue from "vue";

const apiHost = "/api";
// const apiHost = "https://clip.iir.ac.cn/v1/";
const Client = axios.create({
  baseURL: apiHost,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

Client.interceptors.response.use((response) => {
  const data = response.data;
  console.log(data)
  if (data.data) {
    return data.data;
  }
  return [];
});

export default Client;

