<template>
  <div class="pic-search" v-loading="isLoading">
    <div class="block" >
      <!-- <span class="demonstration">{{ fit }}</span> -->
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="keyword" @change="getPic">
      </el-input>
      <div class="pic-list" v-if="result.length > 0" >
        <div class="pic-item" v-for="(item,key) in result" :key="item.url">
           <el-image
          style="width: 100px; height: 100px"
          :src="item.url"
          lazy :preview-src-list="srcList" :fit="fits[key]">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
          <span> <a :href="item.videoUrl" target="_blank">【视频】</a></span>
          <span></span>
          <span>id: {{item.id}}</span>
          <span>catgId: {{item.catgId}}</span>
          <span>filmId: {{item.filmId}}</span>
          <span>sceneId: {{item.sceneId}}</span>
          <span>duration: {{item.duration}}</span>
          <span>score: {{item.score}}</span>
        </div>
      </div>
      <div v-else>
        {{str}}
      </div>
    </div>
  </div>
</template>
<script>
import Client from "../util/resource";

export default {
  name: 'PicSearchIndex',

  data() {
    return {
      isLoading: false,
      keyword: '',
      fits: [], // 'fill', 'contain', 'cover', 'none', 'scale-down'
      result: [],
      srcList: [],
      str: ''
    }
  },

  mounted() {
    // this.getPic()
  },

  methods: {
    getPic() { 
      this.isLoading = true
      Client.get('search?a=' + this.keyword,
      ).then((res) => {
        this.isLoading = false
        this.srcList = []
        if (res) { 
          console.log(res, 'res')
          this.result = res.map(e => { 
            
            // e.url = window.location.origin + e.img
            e.url = e.thumbUrl
            // console.log(e.url)
            this.srcList.push(e.url)
            this.fits.push('contain')
            return e
          })
          console.log(this.srcList, 'this.srcList')
        } else { 
          this.fits = []
          this.result = []
          this.srcList = []
          this.str = 'no data'
        }
        // console.log(res)
      })
    }
  },
};
</script>
<style lang="less">
.el-input__inner{
  // width: 50%;
}
.el-image {
    width: 100% !important;
    height: 160px !important;
}
.el-image__preview{
  width: 100%;
  height: 200px;
}
.el-input {
  width: 50%;
}
.pic-item{
  width: 10% !important;
  height: 50% !important;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: top;
  > span{ 
    width: 100%;
    overflow: hidden;
    text-align: left;
    display: block;
    font-weight: 600;
    color: #1f2f3d;
    word-wrap: break-word;
    height: auto;
    word-break: break-all;
  }
}
.el-image{
  // width: 100% !important;
  // height: 100% !important;
  // padding: 10px;
}
</style>
<style lang="less" scoped>
.pic-search { 
  height: 100%;
  width: 100%;
  .block {
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    height: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .pic-list{
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: left;
  }
}
</style>