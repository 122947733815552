<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 100%;
  height: 100%;
}
body {
  font-size: 62.5%;
  font-size: 14px;
  -webkit-text-size-adjust: 100% !important;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: relative;
  color: #111111;
  font-family: Moderat;
}
</style>
